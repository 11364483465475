import { Button, Caption, H3 } from '@chordco/component-library'
import { TableRow } from 'components/common'
import styled from 'styled-components'

export const Card = styled(TableRow)`
  height: 108px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  display: flex;
  align-content: center;
  width: 100%;

  /* When there's only one card, make it take 50% of the container */
  &:only-child {
    width: 50%;
  }

  /* At smaller screen widths, let the single card take the full width */
  @media (max-width: 1200px) {
    &:only-child {
      width: 100%;
    }
  }

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const Title = styled(H3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
`

export const Description = styled(Caption)`
  color: ${p => p.theme.ContentTertiary};
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  width: 100%;
  overflow: hidden;
`

export const TitleBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

export const StyledIcon = styled(Button)`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: ${p => p.theme.ComponentCardBgElevation1};

  :hover,
  :active,
  :focus {
    background-color: ${p => p.theme.ComponentCardBgElevation1};
    border-color: transparent;
    outline: none;
  }
`

export const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
`
